<header>
  <h1>
    <a routerLink="/">🍕 Pizza Service</a>
  </h1>

  <div class="links">
    <a routerLink="/info">Info</a>
    <a routerLink="/imprint">Imprint</a>
  </div>
</header>

<div class="container">
  <router-outlet></router-outlet>
</div>
